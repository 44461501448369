<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Assessment Form</h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-4">
              <!-- advance serach -->
              <div class="d-flex">
              

                <div class="d-flex flex-column">
                  <label class="col-form-label">Status</label>
                  <div style="width: 170px">
                    <v-select
                      label="name"
                      v-model="status"
                      :options="arr_status"
                      :clearable="false"
                      :selectable="(options) => status.name != options.name"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <!-- <div class="col-form-label d-none">hfhd</div> -->
                  <div @click="refreshData()" class="icon-css">
                    <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                  </div>
                </div>
              </div>
              <!--end advance serach -->

              <div class="d-flex justify-content-end">
                <router-link :to="{ name: 'assessment-form-create' }">
                  <button
                    v-show="!loading"
                    type="button"
                    class="btn btn-sm btn-blue waves-effect waves-light"
                  >
                  <!-- v-if="hasPermissions(['assessment-form-create'])" -->
                    <i class="mdi mdi-plus-circle"></i> Add Assessment Form
                  </button>
                </router-link>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>

            <DataTable
              v-else
              table="dataTable"
              :data="assessmentForm"
              :columns="column"
              route="assessment-form-update"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../../components/shared/DataTable.vue";

import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { levels } from "../../../const";

export default {
  components: {
    vSelect,
    DataTable,
  },

  mixins: [userHasPermissions],

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      assessmentForm: [],
      arr_status: [{ name: "Active" }, { name: "Inactive" }],
      status: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,

      column: [
        {
          data: "id",
          title: "No",
          render: () => {
            this.num++;
            return this.num;
          },
        },
        { data: "name", title: "Name" },
        {
          data: null,
          title: "Status",
          render: (data) => {
            return data.status
              ? `<span class="badge bg-success p-1" style="width: 65px;">Active</span>`
              : `<span class="badge bg-danger p-1" style="width: 65px;">Inactive</span>`;
          },
        },
        {
          data: null,
          title: "Action",
          render: () => {
            return `<i class="mdi mdi-square-edit-outline edit-icon hover fs-4"></i>`;
          },
        },
      ],
    };
  },

  methods: {
    // In-Class
    async getAllAssessmentForm() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-form`)
        .then((response) => {
          this.assessmentForm = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#dataTable").DataTable().draw();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().destroy();
      $("#dataTable").DataTable().draw();
    },

    refreshData() {
      this.clearFilter();
      this.status = "";
      this.assessmentForm = [];
      this.program = "";
      this.num = 0;
      this.getAllAssessmentForm();
    },

    FilterData(program, level, status) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = data[2].includes(program?.short_name)
          ? true
          : false;
        const check_status = status?.name == data[5] ? true : false;
        if (
          (check_status || !status.name) &&
          (check_program || !program.short_name)
        ) {
          return true;
        }

        return false;
      });

      $("#dataTable").DataTable().draw();
    },
  },

  async created() {
    await this.clearFilter();
    await this.getAllAssessmentForm();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
